import './App.css';

function SolarSystem() {
    return (
        <div className="flex">
            <div className="relative">
                <div className="sun">
                    <span>Parker knapp</span>
                </div>
                <div className="planet blue"></div>
                <div className="planet red"></div>
            </div>
        </div>
    );
}

export default SolarSystem;
